import Vue from 'vue';
import Router, {RouteRecord} from 'vue-router';
import AuthService from './services/AuthService';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('./containers/Full.vue'),
            meta: {
                auth: {
                    isProtected: true,
                    requireClaim: {
                        key: 'enf_calltrack_user_type',
                        validValues: ['user', 'admin'],
                    },
                },
            },
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import('./views/Home.vue'),
                },
                {
                    path: '/create',
                    name: 'create',
                    component: () => import('./views/Create.vue'),
                },
            ],
        },
        {
            path: '/callback',
            name: 'callback',
            component: () => import('./views/Callback.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login.vue'),
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('./views/Error.vue'),
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    const authService: AuthService = new AuthService();
    let authRules: any | null = null;

    if (!(to.matched.some((record) => checkAuth(record)))) {
        next();

        return;
    }

    const isLoggedIn = await authService.isLoggedIn();

    if (!isLoggedIn) {
        next({
            path: '/login',
            query: {redirect: to.fullPath},
        });

        return;
    }

    if (authRules != null && authRules.requireClaim !== undefined) {
        const result = await authService.hasClaim(authRules.requireClaim.key, authRules.requireClaim.validValues);

        if (result) {
            return next();
        } else {
            next({
                path: '/error',
            });
        }
    } else {
        next();
    }

    function checkAuth(record: RouteRecord) {
        if (record.meta.auth === undefined) {
            return false;
        }

        authRules = record.meta.auth;

        return record.meta.auth.isProtected;
    }
});

export default router;
