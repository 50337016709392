import Vue from 'vue';
import App from './components/App.vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import moment from 'moment';

// Kendo
import '@progress/kendo-ui/js/kendo.datetimepicker';
import {DateinputsInstaller} from '@progress/kendo-dateinputs-vue-wrapper';
import '@progress/kendo-ui/js/kendo.dropdownlist';
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@progress/kendo-theme-bootstrap/dist/all.css';

library.add(faHome, faPhone);

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.use(BootstrapVue);
Vue.use(DateinputsInstaller);
Vue.use(DropdownsInstaller);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
