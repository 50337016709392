import {User, UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client-ts';

export default class AuthService {
    private userManager: UserManager;

    constructor() {
        const settings: UserManagerSettings = {
            userStore: new WebStorageStateStore({store: window.localStorage}),
            authority: process.env.VUE_APP_IDENTITY_AUTHORITY,
            client_id: process.env.VUE_APP_IDENTITY_CLIENT_ID,
            redirect_uri: process.env.VUE_APP_IDENTITY_REDIRECT_URI,
            automaticSilentRenew: true,
            response_type: 'code',
            scope: 'openid profile enforcement offline_access enf.calltrack',
            post_logout_redirect_uri: process.env.VUE_APP_IDENTITY_POST_LOGOUT_REDIRECT_URI,
            filterProtocolClaims: true,
            loadUserInfo: true
        };

        this.userManager = new UserManager(settings);
    }

    public getUserManager(): UserManager {
        return this.userManager;
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    public async isLoggedIn(): Promise<boolean> {
        const user: User | null = await this.getUser();

        return (user !== null && !user.expired);
    }

    public async hasClaim(claimKey: string, validClaimValues: string[]): Promise<boolean> {
        const user: User | null = await this.getUser();
        if (user === null) {
            return false;
        }

        const userClaimValue = user.profile[claimKey];
        if (userClaimValue === undefined) {
            return false;
        }

        return validClaimValues.some((v) => v === userClaimValue);
    }

    public getAccessToken(): Promise<string> {
        return this.userManager.getUser().then((data: any) => {
            return data.access_token;
        });
    }
}
